import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import { CommunityDescriptionSlice } from '../../types/prismic'
const CommunityDescription = ({
  slice
}: {
  slice: CommunityDescriptionSlice
}) => <PrismicRichText field={slice.primary.introductory_text} />

export default CommunityDescription
